import React from 'react';
import Header from "../Common/Header";
import {galleryImages} from "../../const/assets";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


const GalleryComp = () => {
    return (
        <>
            <Header title="Photo Gallery" brief="Take a look at our photos"/>
            <section id="photoGallery" className="p-5">
                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                    <Masonry gutter="20px">
                        {
                            galleryImages.map((image, index) => (
                                <img src={image} alt="gallery item" key={index} className='galleryImage'
                                     data-toggle='modal' data-target='#exampleModal'
                                />
                            ))
                        }
                    </Masonry>
                </ResponsiveMasonry>
            </section>
        </>
    );
};

export default GalleryComp;