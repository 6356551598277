import React from 'react';

const Header = ({title, brief}) => {
    return (
        <header id="about-header">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center text-white">
                        <h1>{title}</h1>
                        {brief && <p>{brief}</p>}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;