import React from 'react';

const Footer = () => {
    return (
        <footer id="main-footer" className="text-center text-white p-3">
            <div className="container">
                <div className="row mt-3">
                    <div className="col">
                        <p>
                            Copyright &copy; BD Gas and Electric
                        </p>
                        <div className='dev'>
                            <p className='dev-contact text-muted'>Developer Contact:</p>
                            <a className='dev-contact-link text-muted' href="mailto:rafidmostafa13@gmail.com">rafidmostafa13@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;