import React from 'react';
import Header from "../Common/Header";
import ServiceCards from "../Common/ServiceCards";
import {electricalServiceInfo} from "../../const/const";
import NewsLetter from "../HomePage/NewsLetter";

const ElectricalServicesComp = () => {
    return (
        <>
            <Header title={"Electrical Services"} brief={"We provide electrical services for your home and business."}/>
            {electricalServiceInfo && <ServiceCards cardsInfo={electricalServiceInfo}/> }
            <NewsLetter/>
        </>
    );
};

export default ElectricalServicesComp;