import React from 'react';
import {images} from "../../const/assets";


const HomeInfo = () => {
    return (
        <section id="info" className="py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <h3 className='text-uppercase font-italic font-weight-bold'>Why choose us?</h3>
                        <p>
                            We are a highly experienced company covering Domestic and Commercial Electric services as
                            well as covering all kinds of gas and central heating services for domestic properties.
                            We’re based in
                            Grays, Essex and specialise in creating electrical and gas installations, checks and repair
                            for clients across East London and Essex.
                            <br/>
                            We provide quick and comprehensive domestic gas and electrical services including expert
                            installation, maintenance and we are here to help 24/7 in the case of emergency. Our work is
                            fully insured. We have upto £5 million public liability insurance, so you can sit back and
                            relax.
                        </p>
                        {/*<NavLink to="about-us" className="btn btn-outline-info btn-lg mb-3">More About US</NavLink>*/}
                    </div>
                    <div className="col-md-6">
                        <img src={images.ChoseUs} className="img-fluid img-chose-us" alt=""/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeInfo;
