import React, {useRef} from 'react';
import Header from "../Common/Header";
import {address, email, facebook, googleMapLocation, phone} from "../../const/const";
import emailjs from "@emailjs/browser";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactComp = () => {
    const formRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();


    const sendEmail = async (e) => {
        e.preventDefault();
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const message = messageRef.current.value;
        if (firstName && lastName && email && phone && message) {
            try {
                await emailjs.sendForm(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    formRef.current,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                );
                toast.success("Email Sent Successful!");
                firstNameRef.current.value = '';
                lastNameRef.current.value = '';
                emailRef.current.value = '';
                phoneRef.current.value = '';
                messageRef.current.value = '';
            } catch (error) {
                toast.error("Email Sent Failed!");
            }
        } else {
            toast.error("Please fill all the fields!");
        }
    };

    return (
        <>
            <Header title={"Contact Us"} brief={"Get in touch with us"}/>

            <section id="contact" className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card p-4">
                                <div className="card-body pb-0">
                                    <h4 className=" btn-info btn-lg mb-4"> Get In Touch</h4>
                                    <div className="pl-3">
                                        <h4>Address</h4>
                                        <p>{address}</p>
                                        <h4>Email</h4>
                                        <a href={`mailto:${email}`}>{email}</a>
                                        <h4>Phone</h4>
                                        <a href={`tel:${phone}`}>{phone}</a>
                                        <h4>Facebook</h4>
                                        <a href={facebook}>{facebook}</a>
                                        <h4>Location</h4>
                                    </div>
                                </div>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9940.090871981083!2d0.2828512!3d51.4760973!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x729e7e3392bcfdda!2sBD%20Gas%20and%20Electrical%20Service!5e0!3m2!1sen!2ssg!4v1669806977066!5m2!1sen!2ssg"
                                    width="300" height="300" style={{border:0}} allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="card p-4">
                                <div className="card-body">
                                    <h4 className="text-center">Please fill out this form to contact us</h4>
                                    <hr/>
                                    <form ref={formRef} onSubmit={sendEmail}>
                                        <div className="form-row">
                                            <div className="col-md-6 mb-3">
                                                <input ref={firstNameRef} className="form-control" name='firstname' type="text"
                                                       placeholder="First Name"/>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <input ref={lastNameRef}  className="form-control" name='lastname' type="text"
                                                       placeholder="Last Name"/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 mb-3">
                                                <input ref={emailRef}  className="form-control" name='email' type="email"
                                                       placeholder="Email"/>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <input ref={phoneRef}  className="form-control" name='phone' type="text"
                                                       placeholder="Phone Number"/>
                                            </div>
                                        </div>
                                        <div className="form-row mb-3">
                                            <div className="col">
                                                <textarea ref={messageRef}  className="form-control" name="message" rows="3"
                                                          placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col">
                                                <input type="submit" value="Submit"
                                                       className="btn btn-outline-info btn-block"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <ToastContainer position='bottom-center' autoClose={2000}/>
            </section>
        </>
    );
};

export default ContactComp;
