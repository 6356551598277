import Logo from "../img/logo_2_bgrm.png";
import Logo_2 from "../img/logo_3_rmbg.png"
import img_1 from "../img/gallery/1.jpg";
import img_2 from "../img/gallery/2.jpg";
import img_3 from "../img/gallery/3.jpg";
import img_4 from "../img/gallery/4.jpg";
import img_5 from "../img/gallery/5.jpg";
import img_6 from "../img/gallery/6.jpg";
import img_7 from "../img/gallery/7.jpg";
import img_8 from "../img/gallery/8.jpg";
import img_9 from "../img/gallery/9.jpg";
import img_10 from "../img/gallery/10.jpg";
import img_11 from "../img/gallery/11.jpg";
import img_12 from "../img/gallery/12.jpg";
import img_13 from "../img/gallery/13.jpg";
import img_14 from "../img/gallery/14.jpg";
import img_15 from "../img/gallery/15.jpg";
import img_16 from "../img/gallery/16.jpg";
import img_17 from "../img/gallery/17.jpg";
import img_18 from "../img/gallery/18.jpg";
import img_19 from "../img/gallery/19.jpg";
import img_20 from "../img/gallery/20.jpg";
import img_21 from "../img/gallery/21.jpg";
import img_22 from "../img/gallery/22.jpg";
import img_23 from "../img/gallery/23.jpg";
import img_24 from "../img/gallery/24.jpg";
import img_25 from "../img/gallery/25.jpg";
import img_26 from "../img/gallery/26.jpg";
import img_27 from "../img/gallery/27.jpg";
import img_28 from "../img/gallery/28.jpg";
import img_29 from "../img/gallery/29.jpg";
import img_30 from "../img/gallery/30.jpg";
import img_31 from "../img/gallery/31.jpg";
import img_32 from "../img/gallery/32.jpg";
import img_33 from "../img/gallery/33.jpeg";


export const images = {
    Logo,
    Logo_2,
    AboutUs: img_1,
    ChoseUs: img_22
}

// exporting all imported images into an array
const galleryImages =
    [img_1, img_2, img_3, img_6, img_5, img_4, img_7, img_9, img_8, img_10, img_11, img_12, img_13, img_14, img_15, img_16, img_17, img_18, img_19, img_20, img_21, img_22, img_23, img_24, img_25, img_26, img_27, img_28, img_29, img_30, img_31, img_32, img_33];

galleryImages.reverse();

export { galleryImages };
