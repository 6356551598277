import './App.css';
import Home from "./pages/Home";
import Footer from "./components/Common/Footer";
import NavBar from "./components/Common/NavBar";
import React from "react";
import {Route, Routes} from "react-router-dom";
import Gallery from "./pages/Gallery";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import GasService from "./pages/GasService";
import ElectricalService from "./pages/ElectricalService";

function App() {
    return (
        <div className="App">
            <NavBar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/gallery" element={<Gallery/>}/>
                {/*<Route path="/about-us" element={<AboutUs/>}/>*/}
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/gas-service" element={<GasService/>}/>
                <Route path="/electric-service" element={<ElectricalService/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
