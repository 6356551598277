import React from 'react';
import {NavLink} from "react-router-dom";
import {images} from "../../const/assets";
import {email, phone, facebook} from "../../const/const";

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-sm navbar-dark sticky-top bg-dark flex-column">
                <div className='container justify-content-center pre-nav'>
                    <a href={facebook} rel="noreferrer" target='_blank'>
                        <i className='fab fa-facebook'></i>
                        &nbsp;Facebook
                    </a>
                    <a href={`tel:${phone}`}>
                        <i className="fas fa-phone-alt"></i>
                        &nbsp;{phone}
                    </a>
                    <a href={`mailto:${email}`}>
                        <i className="fas fa-envelope"></i>
                        &nbsp;{email}
                    </a>
                </div>
            <div className="container">
                <NavLink to="/" className= {isActive => 'Navbar-brand'}>
                    <img className='logo' src={images.Logo} alt="logo" />
                </NavLink>
                <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div id="navbarCollapse" className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item"><NavLink to="/" className="nav-link">Home</NavLink></li>
                        <li className="nav-item"><NavLink to="electric-service" className="nav-link">Electrical Services</NavLink></li>
                        <li className="nav-item"><NavLink to="gas-service" className="nav-link">Gas Services</NavLink></li>
                        <li className="nav-item"><NavLink to="gallery" className="nav-link">Gallery</NavLink></li>
                        {/*<li className="nav-item"><NavLink to="about-us" className="nav-link">About Us</NavLink></li>*/}
                        <li className="nav-item"><NavLink to="contact" className="nav-link">Contact Us</NavLink></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;


// drop down menu from previous version

// <li className="nav-item dropdown">
//     <div className="nav-link dropdown-toggle" to="" id="navbarDropdown" role="button"
//          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//         Services
//     </div>
//     <div className="dropdown-menu" aria-labelledby="navbarDropdown">
//         <NavLink className={(navInfo) => {
//             return navInfo.isActive ? "dropdown-item active" : "dropdown-item"
//         } } to="gas-service">
//             Gas Services
//         </NavLink>
//         <div className="dropdown-divider"></div>
//         <NavLink className={(navInfo) => {
//             return navInfo.isActive ? "dropdown-item active" : "dropdown-item"
//         } } to="electric-service">
//             Electric Services
//         </NavLink>
//     </div>
// </li>