import React from 'react';
import {featuredServices} from "../../const/const";
import ServiceCards from "../Common/ServiceCards";

const Summary = () => {
    return (
        <section id="home-icon" className="py-5">
                    {
                        <ServiceCards cardsInfo={featuredServices}/>
                    }
        </section>
    );
};


export default Summary;