export const email = "info@bdelectric.co.uk";
export const phone = "+44 7557 016916";
export const address = "584 London Rd, Grays RM20 3BJ, United Kingdom";
export const facebook = 'https://www.facebook.com/bdgasandelectric.co.uk';
export const googleMapLocation = 'https://www.google.com/maps/place/BD+Gas+and+Electrical+Service/@51.4760973,0.2828512,15z/data=!4m5!3m4!1s0x0:0x729e7e3392bcfdda!8m2!3d51.4760777!4d0.2828627?hl=en-GB';
export const gasServiceInfo = [
    {
        title: 'Gas appliance installation',
        description: 'We can install your new gas appliance, including gas cookers, gas hobs, gas boilers and gas water heaters. We can also install gas central heating systems.',
        icon: 'fas fa-burn fa-3x',
    },
    {
        title: 'Gas appliance servicing',
        description: 'We can service your gas appliance, including gas cookers, gas hobs, gas boilers and gas water heaters. We can also service gas central heating systems.',
        icon: 'fas fa-wrench fa-3x',
    },
    {
        title: 'Gas safety checks and certificate',
        description: 'We can carry out gas safety checks and certification on your gas appliances.',
        icon: 'fas fa-check fa-3x',
    },
    {
        title: 'Gas leak detection and repair',
        description: 'We can detect and repair gas leaks.',
        icon: 'fas fa-search fa-3x',
    }
]


export const electricalServiceInfo = [
    {
        title: 'Electrical installation',
        description: 'We can install any new electrical installation, additional sockets, additional circuits, additional new lighting, spotlights, downlights, light switches and anything relating electrical installations.',
        icon: 'bolt',
    },
    {
        title: 'Maintenance',
        description: 'We take care of any electrical replacement of lighting, spotlight, sockets, switches etc.',
        icon: 'wrench',
    },
    {
        title: 'Full and partial rewiring',
        description: 'We can rewire your home or business premises.',
        icon: 'window-minimize',
    },
    {
        title: 'Electrical Fault Finding',
        description: 'Our expert electricians can take care anything related to electrical faults or issues. We are here to help 24/7.',
        icon: 'exclamation-triangle',
    },
    {
        title: 'Electrical safety checks and certification',
        description: 'We can provide all kind of electrical certification, such as, landlord electrical safety certificate (EICR), Electrical installation certificate (EIC), smoke alarm and emergency lighting certificate.',
        icon: 'certificate',
    },
    {
        title: 'PAT Testing',
        description: 'We provide portable appliance testing (PAT) testing for all domestic and commercial properties.',
        icon: 'search',
    }
];

export const featuredServices = [
    {
        title: 'Electrical installation and repair',
        icon: 'bolt',
        link: '/electric-service',
    },
    {
        title: 'Electrical safety certificates',
        icon: 'certificate',
        link: '/electric-service',
    },
    {
        title: 'Gas appliance installation and repair',
        icon: 'burn',
        link: '/gas-service',
    },
    {
        title: 'Gas safety checks',
        icon: 'check',
        link: '/gas-service',
    }
]
