import React from 'react';
import {useNavigate} from "react-router-dom";

const ServiceCards = ({cardsInfo: serviceInfo}) => {
    const navigate = useNavigate();
    const classNamesRed = 'card bg-info text-center text-white';
    const classNamesDark = 'card bg-dark text-center text-white';
    return (
        <section id="icon-boxes" className="p-3 p-sm-5">
            <div className="grid-container">
                {
                    serviceInfo && serviceInfo.map((card, index) =>
                        <div className={index % 2 === 0 ? classNamesRed : classNamesDark} key={index} role='button' onClick={() => navigate(card.link)}>
                            <div className="card-body">
                                <i className={`fas fa-${card.icon} fa-3x`}></i>
                                <h3 className="mt-3">{card.title}</h3>
                                <p>
                                    {card.description}
                                </p>
                            </div>
                        </div>)
                }
            </div>
        </section>
    );
};

export default ServiceCards;