import React from 'react';
import Header from "../Common/Header";
import {gasServiceInfo} from "../../const/const";
import ServiceCards from "../Common/ServiceCards";
import NewsLetter from "../HomePage/NewsLetter";

const GasServiceComp = () => {
    return (
        <>
            <Header title={"Gas Services"} brief={"We provide all kind of gas services for your home."}/>
            {gasServiceInfo && <ServiceCards cardsInfo={gasServiceInfo}/>}
            <NewsLetter/>
        </>
    );
};

export default GasServiceComp;

