import React from 'react';

const GetStarted = () => {
    return (
        <section id="home-heading" className="p-5 mt-5">
            <div className="dark-overlay" style={{position: "relative"}}>
                <div className="row">
                    <div className="col">
                        <div className="container pt-5">
                            <h1>Looking for Gas and Electrical Installation Services?</h1>
                            <p className="d-md-block">
                                Bd Gas and electric are here to help no matter what electrical and gas related issues or projects you have for your home.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GetStarted;