import React, {useRef} from 'react';
import emailjs from "@emailjs/browser";
import {toast, ToastContainer} from "react-toastify";

const NewsLetter = () => {
    const formRef = useRef();
    const phoneRef = useRef();
    const nameRef = useRef();
    const messageRef = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        const phone = phoneRef.current.value;
        const name = nameRef.current.value;
        const message = messageRef.current.value;
        if (phone && name && message) {
            try {
                await emailjs.sendForm(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    formRef.current,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                );
                toast.success("Email Sent Successful!");
                phoneRef.current.value = '';
                messageRef.current.value = '';
                nameRef.current.value = '';
            } catch (error) {
                toast.error("Email Sent Failed!");
            }
        } else {
            toast.error("Please fill all the fields!");
        }
    };

    return (
        <section id="newsletter" className="py-5 bg-dark text-center text-white">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>Send Us Your Feedback and Query</h1>
                        <p>If you have any query, question and feedback about us, please feel free to let us know. We
                            will be more than happy to know your opinion</p>
                        <form ref={formRef} onSubmit={sendEmail} className="form-inline justify-content-center">
                            <input className="form-control mb-2 mr-2" ref={nameRef} type="text" name='firstname'
                                   placeholder="Enter Your Full Name"/>
                            <input className="form-control mb-2 mr-2" ref={phoneRef} type="text" name='phone'
                                   placeholder="Enter Your Phone"/>
                            <input className="form-control mb-2 mr-2" ref={messageRef} type="text" name='message'
                                   placeholder="Write you message"/>
                            <button className="btn btn-primary mb-2" type='submit'>Send</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' autoClose={2000}/>
        </section>
    );
};

export default NewsLetter;
