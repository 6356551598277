import React from 'react';
import Hero from "../components/HomePage/Hero";
import Summary from "../components/HomePage/Summary";
import GetStarted from "../components/HomePage/GetStarted";
import HomeInfo from "../components/HomePage/HomeInfo";
import NewsLetter from "../components/HomePage/NewsLetter";

const Home = () => {
    return (
        <>
            <Hero/>
            <HomeInfo/>
            <GetStarted/>
            <Summary/>
            <NewsLetter/>
        </>
    );
};

export default Home;