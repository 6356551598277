import React from 'react';
import {phone} from "../../const/const";

const Hero = () => {
    return (
        <div className="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="hero-heading">
                                We Provide Bespoke Gas & Electrical Installation Services
                            </h1>
                            <p className="hero-sub-heading">Gas and Electrical Installation Services in East London and Essex! We are here to help you 24/7. Call us or leave us a message.</p>
                            <a href={`tel:${phone}`} className="btn btn-info btn-lg">
                                <i className="fas fa-phone-alt"></i> {phone}
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Hero;